import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CUSTOMER_CARE_EMAIL, SHOPINIWORLD_LINK } from '../../constants/constants'
import { BUY_IT_FOR_ME } from '../../constants/navigationConstants'
function TermsConditionsPage() {
    const { t, i18n } = useTranslation()

    return (
        <div className="global-section-container flex-col">
            <div
                className={`main-section-container container ${i18n.language === 'ar' ? 'rtl-direction' : 'ltr-direction'} text-start`}
            >
                <div id="direction_conditions" className="widget__inner mobile-no-padding">
                    <h1 className="sub-page-header mb-5">{t('Terms and conditions')}</h1>
                    <p>
                        <strong>{t('This account agreement is made between User')}</strong>
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>
                    </p>
                    <p>
                        {t('Welcome to')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>
                        .{t('The')}
                        <a
                            className={`${i18n.language === 'ar' ? 'd-none' : ''} mx-1`}
                            target="_blank"
                            href={`https://${SHOPINIWORLD_LINK}`}
                            rel="noreferrer"
                        >
                            {SHOPINIWORLD_LINK}
                        </a>
                        {t('website')}
                        <a
                            className={i18n.language === 'ar' ? 'ml-1' : 'mr-1'}
                            target="_blank"
                            href={`https://${SHOPINIWORLD_LINK}`}
                            rel="noreferrer"
                        >
                            {SHOPINIWORLD_LINK}
                        </a>{' '}
                        {t('is offered to you conditioned on your acceptance')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>
                        {t('constitutes your agreement to all such Terms')}
                        <br />
                        <br />
                    </p>
                    <p>
                        <strong>{t('Definitions and Interpretation')}</strong>
                    </p>
                    <p>
                        “<strong>{t('Package')}</strong>”: {t('means any parcel that travels under one')}
                    </p>
                    <p>
                        “<strong>{t('Shipping Waybill')}</strong>”:{' '}
                        {t('this means any shipping label generated any courier or shipping company')}
                    </p>
                    <p>
                        "<strong>{t('Dangerous Goods')}</strong>":{' '}
                        {t('means any commodity that is classified as hazardous')}
                    </p>
                    <p>
                        "<strong>{t('Goods')}</strong>": {t('are any items which are the subject')}
                        <br />
                    </p>
                    {/*<p>
                        <strong>{t('United Arab of Emirates is our country of domicile')}</strong>
                    </p>*/}
                    <p>
                        <strong>
                            <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                                {SHOPINIWORLD_LINK}
                            </a>{' '}
                            {t('is an E-Commerce Site')}
                        </strong>
                        &nbsp;
                    </p>
                    <p>
                        <strong>{t('Service')}</strong>:
                    </p>

                    <p>Our Services include the following services :</p>

                    <ul>
                        <li>Procurement of goods</li>
                        <li>Online purchases</li>
                        <li>Value added services</li>
                        <li>Re-packing and consolidation</li>
                        <li>Goods quality inspection</li>
                        <li>Freight and shipping services</li>
                    </ul>

                    <p>
                        {t('Our Service enables all users')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>
                        . {t('please take note that shipments cannot be delivered')}
                        <br /> <br /> {t('To avail of this service from us')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>
                        {t('and pay the monthly or yearly registration fees')}
                        <br />
                        <br />
                    </p>
                    <p>
                        &nbsp;<strong>{t('Buy it for me service')}</strong>
                    </p>
                    <p>{t('Once you register')}</p>
                    <p>
                        &nbsp;<strong>{t('Payment bifm')}</strong>
                    </p>
                    <p>
                        <ul>
                            <li>{t('If the payment matches your payment')}</li>
                            <li>{t('If the price of the item')}</li>
                            <li>
                                {t('If the item price is higher than your payment')}
                                <br />
                                {/* {t("please be aware")} */}
                                <ol type="a">
                                    <li>{t('Available balance in the Wallet')}</li>
                                    <li>{t('No available balance in the Wallet')}</li>
                                </ol>
                            </li>
                        </ul>
                    </p>
                    <p>
                        <strong>&nbsp;{t('Cancellation')}</strong>
                    </p>
                    <p>{t('Cancellation is not possible')}</p>
                    <p>
                        <strong>&nbsp;{t('returning bifm')}</strong>
                    </p>
                    <p>
                        {t('Please contact our customer service team at')}
                        &nbsp;<a href={`mailto:${CUSTOMER_CARE_EMAIL}`}>customercare@shopiniworld.com</a>&nbsp;
                        {t('to assist you with the return process')}
                    </p>
                    <p>
                        <strong>&nbsp;{t('Wallet')}</strong>
                    </p>
                    <p>{t('The Wallet is a feature')}</p>
                    <p>
                        <strong>&nbsp;{t('Refer a Friend Program')}</strong>
                    </p>
                    <p>
                        <ul>
                            <li>
                                {t(
                                    'USD5 will be added once the friend referred ships his/her first shipment with ShopiniWorld',
                                )}
                            </li>
                            <li>{t('You can get USD5 on every friend you refer as long as he/she starts to ship')}</li>
                            <li>{t('The credit will be added one time only for each active friend you refer')}</li>
                            <li>
                                {t('The credit will be added on the first shipment only for the friend you referred')}
                            </li>
                            <li>
                                {t(
                                    'The credit balance can be used on “Buy it for me” Service only, to learn more about the service please visit',
                                )}
                                <Link
                                    onClick={() =>
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth',
                                        })
                                    }
                                    to={BUY_IT_FOR_ME}
                                >
                                    https://shopiniworld.com/buy-it-for-me
                                </Link>
                            </li>
                        </ul>
                    </p>
                    <p>
                        <strong>&nbsp;{t('Privacy')}</strong>
                    </p>
                    <p>
                        {t('Your use of')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>{' '}
                        {t('is subject to Companys Privacy Policy')}
                    </p>
                    <p>
                        <strong>
                            <br />
                            {t('Electronic Communications')}
                        </strong>
                    </p>
                    <p>
                        {t('Visiting')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>{' '}
                        {t('or sending emails to Company constitutes electronic communications')}
                    </p>
                    <p>
                        &nbsp;
                        <br />
                        <strong>{t('Your Account')}</strong>
                    </p>
                    <p>{t('If you use this site')}</p>
                    <p>
                        &nbsp;<strong>{t('Receipt and shipping')}</strong>
                    </p>
                    <p>{t('Our services would include the receipt of your packages')}</p>
                    <p>{t('At that moment, all our customers')}</p>
                    <p>{t('All our customers are required to provide us with invoices')}</p>
                    <p>
                        &nbsp;<strong>{t('Quality check')}</strong>
                    </p>
                    <p>{t('Shopini Logistics, without liability')}</p>
                    <p>
                        &nbsp;<strong>{t('Shipping fees')} </strong>
                    </p>
                    <p>{t('Our special shipping fees will be charged after')}</p>
                    <p>
                        &nbsp;<strong>{t('Delivery/Shipment Policy')}</strong>
                    </p>
                    <p>
                        {t('will NOT deal')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>
                        {t('will NOT deal or provide any services or products')}
                    </p>
                    <p>
                        {t(
                            'Multiple transactions may result in multiple postings to the cardholder’s monthly statement',
                        )}
                    </p>
                    <p>{t('Please note that all prices listed on our website are')}</p>
                    <p>
                        {t('Moreover')}
                        <br /> &nbsp;
                        <br /> {t('We reserve the right at any time to charge a fee')}
                    </p>
                    <p>{t('You acknowledge and agree that any rate')}</p>
                    <p>
                        &nbsp;<strong>{t('legal use of your account')}</strong>
                    </p>
                    <p>{t('You undertake not to use the Service')}</p>
                    <p>{t('Any dispute or claim arising')}</p>
                    <p>
                        &nbsp;<strong>{t('We can not handle')}</strong>
                    </p>
                    <p>{t('All customers understand and agree')}</p>
                    <ul>
                        <li>{t('Items that are classified as hazardous or dangerous goods')}</li>
                        <li>{t('Jewellery, precious stones, pornographic material')}</li>
                        <li>{t('IDs and licenses issued by Governments')}</li>
                        <li>{t('Shipments containing Dangerous Goods')}</li>
                    </ul>
                    <p>
                        &nbsp;<strong>{t('Sanctioned business Entities')}</strong>
                    </p>
                    <p>{t('When using our website to subscribe and use our receiving')}</p>
                    <p>
                        &nbsp;<strong>{t('Payments')}</strong>
                    </p>
                    <ul>
                        <li>{t('If you make a payment for our products or services on our website')}</li>
                        <li>{t('The cardholder must retain a copy')}</li>
                        <li>{t('We accept payments online using Visa')}</li>
                    </ul>
                    <p>
                        &nbsp;<strong>{t('Promo Codes')}</strong>
                    </p>
                    <ol>
                        <li>{t('Promo codes cannot be used with discounted rates')}</li>
                        <li>{t('Shipping promo code cannot be used')}</li>
                    </ol>
                    <p>
                        &nbsp;<strong>{t('Export Declaration')}:</strong>
                    </p>
                    <p>{t('All our customers are required to provide us with invoices')}</p>
                    <p>
                        &nbsp;<strong>{t('Children Under Eighteen')}</strong>
                    </p>
                    <p>{t('Minors under the age of 18 are prohibited')}</p>
                    <p>
                        &nbsp;<strong>{t('Cancellation Policy')}</strong>
                    </p>
                    <p>
                        {t('You may terminate your subscription under this Agreement')}
                        <a href={`mailto:${CUSTOMER_CARE_EMAIL}`}>customercare@shopiniworld.com</a>{' '}
                        {t('with any questions')}
                    </p>
                    <p>{t('Refunds will be done only through the Original Mode of Payment')}</p>
                    <p>
                        {t('Please contact our customer service team at')}
                        <a href={`mailto:${CUSTOMER_CARE_EMAIL}`}>customercare@shopiniworld.com</a>
                        {t("to arrange for the refund if the shipment's condition received as followed")}
                    </p>
                    <ul>
                        <li>{t('The wrong product was sent by the merchant')}</li>
                        <li>{t('The product is defective')}</li>
                        <li>{t('The product was damaged in shipping')}</li>
                        <li>{t('The product is tampered')}</li>
                    </ul>
                    <p>{t('All customers agree that Shopini')}</p>
                    <ol>
                        <li>{t('Your use of the Account for illegal purposes or for any purpose prohibited by us')}</li>
                        <li>{t('Your failure to pay monies owed to Shopini Logistics LLC when due')}</li>
                        <li>{t('Your violation of any provision of these Terms and Conditions')}</li>
                    </ol>
                    <p>
                        &nbsp;
                        <strong>{t('Links to Third Party Sites/Third Party Services')}</strong>
                    </p>
                    <p>
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>{' '}
                        {t('may contain links to other websites')}
                    </p>
                    <p>
                        &nbsp;{t('Certain services made available via')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>{' '}
                        {t('are delivered by third party sites and organizations')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>
                        {t('domain')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>{' '}
                        {t('users and customers')}
                    </p>
                    <p>
                        &nbsp;
                        <strong>{t('No Unlawful or Prohibited Use/Intellectual Property')}</strong>
                    </p>
                    <p>
                        {t('You are granted a non-exclusive')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>
                        {t('strictly in accordance with these terms of use')}
                    </p>
                    <p>&nbsp;{t('All content included as part of the Service')}</p>
                    <p>&nbsp;{t('You will not')}</p>
                    <p>
                        &nbsp;<strong>{t('Indemnification and Warranty')}</strong>
                    </p>
                    <p>{t('You confirm that all information provided by you is accurate')}</p>
                    <ol>
                        <li>{t('You agree to protect')}</li>
                        <li>{t('Failure of services')}</li>
                        <li>{t('Any delay caused by handling')}</li>
                        <li>{t('Any misdescription or undervaluation provided')}</li>
                        <li>{t('Damage to or loss of any package')}</li>
                    </ol>
                    <p>{t('Without limiting any other provision of these Terms and Conditions')}</p>
                    <p>
                        &nbsp;<strong>{t('Force Majeure')}</strong>
                    </p>
                    <p>{t('Shopini Logistics LLC will not be liable to you for any delay')}</p>
                    <p>
                        &nbsp;<strong>{t('Third Party Accounts')}</strong>
                    </p>
                    <p>{t('You will be able to connect your Company account to third party accounts')}</p>
                    <p>
                        &nbsp;<strong>{t('International Users')}</strong>
                    </p>
                    <p>
                        {t('The Service is controlled')}
                        <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                            {SHOPINIWORLD_LINK}
                        </a>{' '}
                        {t('in any country or in any manner')}
                    </p>
                    <p>
                        &nbsp;<strong>{t('Indemnification')}</strong>
                    </p>
                    <p>{t('You agree to indemnify')}</p>
                    <p>
                        &nbsp;<strong>{t('Arbitration')}</strong>
                    </p>
                    <p>{t('In the event the parties are not able to resolve any dispute')}</p>
                    <p>
                        &nbsp;<strong>{t('Class Action Waiver')}</strong>
                    </p>
                    <p>{t('Any arbitration under these terms and conditions will take place')}</p>
                    <p>
                        &nbsp;<strong>{t('Liability Disclaimer')}</strong>
                    </p>
                    <p>{t('The information, software, products, and services')}</p>
                    <p>{t('Shopini logistics LLC and/or its suppliers')}</p>
                    <p>{t('To the maximum extent permitted by applicable law')}</p>
                    <p>
                        &nbsp;<strong>{t('Termination/Access Restriction')} </strong>
                    </p>
                    <p>{t('Company reserves the right, in its sole discretion, to terminate your access')}</p>
                    <p>{t('You agree that no joint venture')}</p>
                    <p>{t('Unless otherwise specified herein')}</p>
                    <p>
                        &nbsp;<strong>&nbsp;</strong>
                        <strong>{t('Changes to Terms')}</strong>
                    </p>
                    <p>
                        {/* {t("Company reserves the right")}
						<a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
							{SHOPINIWORLD_LINK}
						</a>{" "}
						{t("is offered")} */}
                        {t('The Website Terms')}
                    </p>
                    <p>
                        &nbsp;<strong>{t('Contact Us')}</strong>
                    </p>
                    {/* <p>
						{t("Company welcomes your questions or comments regarding the Terms:")}
					</p> */}
                    <p className="description">
                        {/*{t('Shopini Logistics LLC')}
                        <br />
                        {t('183 edgemoor road,')}
                        <br />
                        {t('Wilmington, DE')}
                        <br />
                        {t('PO Box 19809')}*/}
                        <br />
                        {t('Phone: +1 646 209 4112')}
                        <br />
                        {t('Email address')}: support@shopiniworld.com
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TermsConditionsPage
